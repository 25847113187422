function checkIsNotNullOrUndefined(variable) {
  if (variable == null) {
    return false;
  }
  return true;
}

function handleToStringCoersionOfVariables(variable) {
  return checkIsNotNullOrUndefined(variable) ? variable : '';
}

export default handleToStringCoersionOfVariables;
