import React from 'react';
import styled from 'styled-components';
import { H2, Button } from '@allthingswww/client-act-shared';

import moment from 'moment';
import Image from './Image';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledProfile = styled.div`
  border-top: 1px solid #0f0f3a;
  border-bottom: 1px solid #0f0f3a;
  padding: 30px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 30px 0;
  & > div + div {
    margin-left: 30px;
  }
`;

const StyledImageAndEdit = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledImage = styled.div`
  width: 60%;
  flex-grow: 1;
`;

const StyledEdit = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 40%;
  margin-top: 110px;
`;

const TitleContainer = styled.div``;

const EditButtonContainer = styled.div``;

const StyledContactACT = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 33px;
  margin-top: 110px;

  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: right;

  /* Silver */
  color: #8a8582;
`;

const DateAndDesc = styled.div``;

const DateContainer = styled.div`
  flex-grow: 1;
`;

const DateTimeContainer = styled.div`
  width: 100%;
  display: flex;
  font-size: 24px;
  font-weight: bold;
`;

const Profile = ({ profileImage, name, machineDate }) => (
  <MainContainer>
    <StyledProfile>
      <StyledImageAndEdit>
        <StyledImage>
          <Image fileName={profileImage} width="300px" height="300 px" />
        </StyledImage>
        <StyledEdit>
          <TitleContainer>
            <H2>{name}</H2>
          </TitleContainer>
          <EditButtonContainer>
            <Button.Button variant>Edit Profile</Button.Button>
          </EditButtonContainer>
        </StyledEdit>
      </StyledImageAndEdit>
      <StyledContactACT>Contact ACT</StyledContactACT>
    </StyledProfile>
    <DateAndDesc>
      <DateTimeContainer>
        <DateContainer>
          Date -
          {' '}
          {moment({ machineDate }).format('dddd, MMMM DD, YYYY')}
        </DateContainer>
      </DateTimeContainer>

    </DateAndDesc>
  </MainContainer>
);

export default Profile;
