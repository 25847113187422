import React from 'react';
import styled from 'styled-components';
import {
  MaxWidth, Button, H1, H2,
} from '@allthingswww/client-act-shared';
import Image from './Image';

const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledButtonContainer = styled.div`
  display: flex;
`;
const ContainerWrapper = styled(MaxWidth)`

`;
const StyledTitle = styled.div`
  text-align: center;
  margin: 30px 0;
  @media all and (max-width: 1280px) {
    display: none;
  }
`;
const StyledImageAndDescription = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${(props) => (props.primary ? 'row-reverse' : 'row')};
  justify-content: space-evenly;
  flex-wrap: wrap;
  & h2 {
    line-height: 32px;
    margin-bottom: 10px;
  }
`;

const ProductDesc = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  padding: 0 10px;

  color: ${(props) => (props.primary ? 'black' : '#fff')};

  p {
    margin: 32px 0px;
  }

  @media all and (max-width: 1280px) {
    text-align: center;
    margin: 16px;
  }
  @media (min-width: 1050px) {
    padding-left: 20px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > img {
    max-width: 100%;
  }
`;

const InfoSection = ({
  title,
  descTitle,
  desc,
  image,
  buttonText,
  primary,
  actionTarget,
}) => (
  <StyledMainContainer primary={primary}>
    <ContainerWrapper>
      <StyledTitle>
        <H1 color={primary ? '#0f0f3a' : 'white'}>{title}</H1>
      </StyledTitle>
      <StyledImageAndDescription primary={primary}>
        <ProductDesc primary={primary}>
          <H2 color={primary ? '#0f0f3a' : 'white'} margin="0 10px 0 0">
            {descTitle}
          </H2>

          <div>{desc}</div>
          <StyledButtonContainer>
            <Button.Button
              href={actionTarget}
              color={primary ? '#0f0f3a' : 'white'}
              aria-label={buttonText}
            >
              {buttonText}
            </Button.Button>
          </StyledButtonContainer>
        </ProductDesc>

        <ImageWrapper>
          <Image fileName={image} width="450px" height="450px" />
        </ImageWrapper>
      </StyledImageAndDescription>
    </ContainerWrapper>
  </StyledMainContainer>
);

export default InfoSection;
