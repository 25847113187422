import styled from 'styled-components';

const BreakOutSection = styled.div`
position: relative;
justify-content: center;
margin-left: calc(50% - 50vw);
margin-right: calc(50% - 50vw);
justify-content: center;
`;

export default BreakOutSection;
