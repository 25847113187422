import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { ErrorBoundary } from '@allthingswww/client-act-shared';

import components from '../registerMdxComponents';

export default function PageTemplate({ source }) {
  return (
    <ErrorBoundary>
      <MDXProvider components={components}>
        <MDXRenderer>{source}</MDXRenderer>
      </MDXProvider>
    </ErrorBoundary>
  );
}
