/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Input, DropDown } from '@allthingswww/client-act-shared';
import { prop } from 'styled-tools';

import {
  getEmergencyMembers,
  getMembers,
  getMembersByType,
} from '../redux/members';
import useSearch from './useSearch';
import SimplifiedSearch from './SimplifiedSearch';

import useForm from '../../functions/utils/useForm';
import {
  transformSpecialitiesStringToID,
  transformContractorData,
} from '../../functions/utils/occupations';

const StyledInputForm = styled.form`
  @media print {
    display: none;
  }
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
`;

const SearchResultsSection = styled.div`
  @media print {
  html {
    font-size: 8pt;
  }
  }
  margin-top: 1.5rem;
  display: flex;
  flex-flow: column nowrap;
  min-height: 25vh;
  border-bottom: 2px solid ${prop('theme.color.blue', '#0F0F3A')};

  & > h1 {
    text-align: center;
    margin-top: 60px;
  }

  & > h2 {
    text-align: center;
    margin-top: 10px;
  }
`;

const FlexGrow = styled.div`
  flex-grow: ${prop('of', '1')};
`;

const StyledInput = styled(Input)`
  margin: 0rem;
`;

const getOccupationFromURL = () => {
  const [occupation] = typeof window !== 'undefined'
    ? window.location.pathname.split('/').reverse()
    : '';
  return occupation;
};

const Container = styled.div`

`;

const Search = ({
  uidFieldName,
  indexList,
  isEmergencyOnly = false,
  preloadTypeFromURL,
  autoSearch = false,
}) => {
  const allMembers = useSelector(getMembers);
  const emergencyMembers = useSelector(getEmergencyMembers);
  const findMembersOfType = useSelector(getMembersByType);
  const [list, setList] = useState(allMembers);
  const allTypes = transformContractorData(allMembers);
  const formattedTypes = allTypes.map((index) => {
    const labelHumanReadable = index?.label?.split('_').join(' ');
    return ({
      id: index.id,
      name: labelHumanReadable,
      value: index.label,
    });
  });

  const [
    { searchResults, isSingular, searchCount },
    { updateSearchQuery, setSearchResults },
  ] = useSearch({
    uidFieldName,
    indexList,
    itemsToBeSearched: list,
  });

  const {
    formData, onChange, onSubmit, setFormData,
  } = useForm(
    {
      query: '',
      emergencyOnly: isEmergencyOnly,
      type: preloadTypeFromURL ? getOccupationFromURL() : '',
    },
    ({ query, type, emergencyOnly }) => {
      if (type && emergencyOnly) {
        const searchByTypeEmergency = getMembersByType({
          members: { members: emergencyMembers },
        });
        const onlyMembersWithTypeEm = searchByTypeEmergency(
          transformSpecialitiesStringToID(type),
        );
        setList(onlyMembersWithTypeEm);
        return updateSearchQuery(query);
      }
      if (type || emergencyOnly) {
        if (type) {
          const allMemberWithSelectedType = findMembersOfType(
            transformSpecialitiesStringToID(type),
          );
          setList(allMemberWithSelectedType);
        } else {
          setList(emergencyMembers);
          updateSearchQuery(query);
        }
        return updateSearchQuery(query);
      }

      setList(allMembers);
      return updateSearchQuery(query);
    },
  );

  const { query, emergencyOnly, type } = formData;

  useEffect(() => {
    if (preloadTypeFromURL) {
      setFormData({ ...formData, type: getOccupationFromURL() });
    }
  }, []);

  useEffect(() => {
    if (autoSearch) {
      if (type && emergencyOnly) {
        const searchByTypeEmergency = getMembersByType({
          members: { members: emergencyMembers },
        });
        const onlyMembersWithTypeEm = searchByTypeEmergency(
          transformSpecialitiesStringToID(type),
        );
        setSearchResults(onlyMembersWithTypeEm);
        setList(onlyMembersWithTypeEm);
        return updateSearchQuery(query);
      }

      if (type || emergencyOnly) {
        if (type) {
          const allMemberWithSelectedType = findMembersOfType(
            transformSpecialitiesStringToID(type),
          );
          setSearchResults(allMemberWithSelectedType);
          setList(allMemberWithSelectedType);
        } else {
          setSearchResults(emergencyMembers);
          setList(emergencyMembers);
          updateSearchQuery(query);
        }
        return updateSearchQuery(query);
      }
      setSearchResults(allMembers);
      setList(allMembers);
      return updateSearchQuery(query);
    }
  }, [query, emergencyOnly, type, allMembers]);

  useEffect(() => {
    updateSearchQuery(query);
  }, [emergencyOnly]);

  return (
    <Container>
      <StyledInputForm onSubmit={onSubmit}>
        <FlexGrow of="7">
          <StyledInput
            handleChange={onChange}
            name="query"
            value={query}
            placeholder="Enter Part of a Member Name or Company*"
            autofocus
          />
        </FlexGrow>
        <FlexGrow>
          <DropDown
            options={formattedTypes}
            name="type"
            value={type}
            onChange={onChange}
            disabled={false}
            placeholder="Choose speciality"
          />
        </FlexGrow>
      </StyledInputForm>
      <SearchResultsSection>
        {allMembers.length > 0 && (
          <SimplifiedSearch
            searchResults={searchResults}
            isSingular={isSingular}
            searchCount={searchCount}
          />
        )}
      </SearchResultsSection>
    </Container>
  );
};

export default Search;
