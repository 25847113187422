import React from 'react';
import styled from 'styled-components';
import { H2, Button } from '@allthingswww/client-act-shared';
import Carousel from './Carousel';
import TitleAndDescription from './TitleAndDescription';

const CarouselContainer = styled.div`
.embla {
    position: relative;
  }
  
  .embla__viewport {
    overflow: hidden;
  }
  
  .embla__viewport.is-draggable {
    cursor: move;
    cursor: grab;
  }
  
  .embla__viewport.is-dragging {
    cursor: grabbing;
  }
  
  .embla__container {
    display: flex;
    will-change: transform;
    margin-left: -1rem;
  }
  
  .embla__slide {
    flex: 0 0 auto;
    width: 100%;
    position: relative;
    padding-left: 1rem;
    counter-increment: embla;
  }
  
  .embla__slide__inner {
    position: relative;
    border-radius: 0.5rem;
    font-size: 5rem;
    
  }
  

  .embla__button {
    background-color: transparent;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(calc(-50% - 30px));
    border: 0;
    width: 5rem;
    height: 5rem;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  
  .embla__button:not(:disabled) {
    cursor: pointer;
    fill: #ffffffcc;
  }
  
  .embla__button:disabled {
    fill: var(--lightgrey)
  }
  
  .embla__button__svg {
    width: 3.5rem;
    height: 3.5rem;
  }
  
  .embla__button--prev {
    left: 0.7rem;
  }
  
  .embla__button--next {
    right: 0.7rem;
  }
  
  .embla__dots {
    position: absolute;
    margin-top: 1rem;
    display: flex;
    list-style: none;
    padding-left: 0;
    justify-content: center;
    left: 0;
    right: 0;
    /* top: 100%; */
  }
  
  .embla__dot {
    background-color: transparent;
    cursor: pointer;
    position: relative;
    padding: 0;
    width: 3rem;
    height: 3rem;
    margin-right: 0.75rem;
    margin-left: 0.75rem;
    border: 0;
    display: flex;
    align-items: center;
  }
  
  .embla__dot:after {
    background-color: #efefef;
    width: 100%;
    height: 0.4rem;
    content: "";
  }
  
  .embla__dot.is-selected:after {
    background-color: var(--primary);
    opacity: 1;
  }
  
  .autoplay {
    margin-top: 40px;
    margin-left: 20px;
  }
  
  margin-bottom: 1em;
`;

const CarouselImage = styled.img`
  width: 100%;
  margin-top: -100px;
  
`;

const MegaTitle = styled.h1`
  margin: 20px 0 0;
  font-size: 100px;
  font-weight: 300;
  @media (max-width: 1060px) {
    font-size: 75px;
  }
  @media (max-width: 810px) {
    font-size: 60px;
  }
  @media (max-width: 655px) {
    font-size: 45px;
  }
  @media (max-width: 470px) {
    font-size: 30px;
  }
  @media (max-width: 350px) {
    font-size: 25px;
  }
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MiniTitle = styled.div`
  margin: 0;
  font-size: 50px;
  font-weight: 300;
  @media (max-width: 810px) {
    font-size: 35px;
  }
  @media (max-width: 655px) {
    font-size: 25px;
  }
  @media (max-width: 470px) {
    font-size: 15px;
  }
  text-align: center;
`;
const TitleWrapper = styled.div`
  margin-top: 3em;
`;

const EventSummaryContainer = styled.div`
  text-align: center;
  p {
    font-size: 1.2rem;
    padding-top: 0.5rem;
    margin: 0 5rem;
    line-height: 1.5;
  }
`;

const StyledH2 = styled(H2)`
  margin: 0;
`;

const CarouselItems = ({
  carouselData = [], title, miniTitle, delay = '4000',
}) => (
  <CarouselContainer>
    <Carousel delay={delay}>
      {carouselData.map((item) => (
        <>
          <CarouselImage src={item.src} alt={item.alt} />
          <EventSummaryContainer>
            <StyledH2>{item.eventTitle}</StyledH2>
            <p>{item.eventSubTitle}</p>
            <Button.Button variant href={item.url}>View event</Button.Button>
          </EventSummaryContainer>
        </>
      ))}
    </Carousel>
    <TitleWrapper>
      <MegaTitle>{title}</MegaTitle>
      <MiniTitle>{miniTitle}</MiniTitle>
    </TitleWrapper>
  </CarouselContainer>
);

export default CarouselItems;
