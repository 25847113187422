import React from 'react';
import styled from 'styled-components';
import { Link } from '@allthingswww/client-act-shared';
import Image from './Image';

const Container = styled.div`
  transition: 0.3s;
  &:hover {
    transform: scale(1.05);
  }
`;
const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 400px;
  margin: 30px;
  margin-bottom: 80px;
  @media all and (max-width: 1280px) {
    width: 80%;
    margin: 20px auto;
  }

  h4 {
    margin: 20px 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    color: #ffffff;
  }

  p {
    color: #ffffff;
    line-height: 25px;
  }
`;

const ImageWithDescription = ({
  image, width, height, title, desc, url,
}) => {
  const content = (
    <ImageContainer>
      <Image fileName={image} width={width} height={height} />
      <h4>{title}</h4>
      <p>{desc}</p>
    </ImageContainer>
  );

  if (url) {
    return (
      <Container>
        <Link to={url} aria-label={title}>
          {content}
        </Link>
      </Container>
    );
  }
  return content;
};

export default ImageWithDescription;
