import React from 'react';
import styled from 'styled-components';
import { EventDetails } from '@allthingswww/client-act-shared';
import moment from 'moment';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 636px) {
    & > div {
      width: 100%;
      margin: 15px 0px;
      padding: 0 10px;
    }
  }
`;

const EventsList = ({ events }) => (
  <MainContainer>
    {events
      .map((event) => {
        const date = moment(event.date).format('MM/DD/YY hh:mm A');
        return (
          <EventDetails
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...event}
            key={event._id}
            slug={`event/${event._id}`}
            eventTitle={event.name}
            eventDescription={event.shortDescription}
            date={date === 'Invalid date' ? '' : date}
          />
        );
      })}
  </MainContainer>
);

export default EventsList;
