// import React from 'react';
/* eslint-disable import/no-duplicates */
import * as masterPieceUI from 'masterpiece-ui';
import { MDXMappings } from 'masterpiece-ui';
/* eslint-enable import/no-duplicates */
// import { theme, ifProp } from 'styled-tools';
// import { variant, flex } from 'styled-system';
// import { themeGet } from '@styled-system/theme-get';
// import { Link } from 'gatsby';
import { TitleAndParagraph } from '@allthingswww/client-act-shared';

import StyledH1 from './components/StyledH1';
import StyledH2 from './components/StyledH2';
import StyledP from './components/StyledP';
import StyledListItem from './components/StyledListItem';
import { ImagePane } from './components/Panes';
import JaggedContainer from './components/Home/JaggedContainer';
import Sponsors from './components/Sponsors';
import Tribute from './components/Tribute';
import SplitContainer from './components/Home/SplitContainer';
import Events from './components/Events';
import ImageWithDescription from './components/ImageWithDescription';
import EventCalendar from './components/EventCalendar';
import Carousel from './components/Carousel';
import CarouselItems from './components/CarouselItems';
import Search from './components/Search';

import PurchasePlanForm from './components/SignUp/PurchasePlanForm';
import ChooseNatureOfBusiness from './components/SignUp/ChooseNatureOfBusiness';
import StepOne from './components/SignUp/StepOne';
import StepTwo from './components/SignUp/StepTwo';
import BlockCentered from './components/Center';
import SocialIcons from './components/SocialIcons';
import RowSection from './components/RowSection';
import Profile from './components/Profile';
import Survey from './components/SurveyDispatch';
import Join from './components/Join';
import Checkout from './components/Checkout';

const signup = {
  StepOne,
  StepTwo,
  ChooseNatureOfBusiness,
};

const components = {
  ...MDXMappings,
  ...masterPieceUI,
  ...signup,
  h1: StyledH1,
  h2: StyledH2,
  p: StyledP,
  li: StyledListItem,
  BlockCentered,
  Events,
  Sponsors,
  Tribute,
  SplitContainer,
  EventCalendar,
  JaggedContainer,
  ImagePane,
  SocialIcons,
  Profile,
  RowSection,
  Button: () => 'button',
  ImageWithDescription,
  Carousel,
  CarouselItems,
  TitleAndParagraph,
  PurchasePlanForm,
  Survey,
  Search,
  Join,
  Checkout,
  // SignUp,
  // EmergencyList,
  // Referrals,
  // Mission,
  // OurContractorsFinder,
  // Permits,
  // BeCareful,
  // SocialMediaSmall,
};

export default components;
