import React from 'react';
import styled from 'styled-components';
import Image from './Image';

const MainContainer = styled.div`
  display: inline-flex ;
  margin-right: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
`;

const SocialIcons = ({
  image, url,
}) => (
  <MainContainer>
    <a href={url}>
      <Image fileName={image} width="16px" height="16px" />
    </a>

  </MainContainer>
);

export default SocialIcons;
