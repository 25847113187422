import React from 'react';
import styled from 'styled-components';
import { H2 } from '@allthingswww/client-act-shared';
import { prop } from 'styled-tools';

const StyledMainContainerForDoubleImage = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  @media all and (max-width: 1280px) {
    width: 80%;
    margin: 0 auto;
  }
`;

const StyledTitleForOurBusiness = styled.div`
  text-align: center;
  @media all and (max-width: 1280px) {
    width: 80%;
    margin: 0 auto;
  }
`;

const StyledOurBusiness = styled.div`
  background-color: ${prop('theme.color.blue', '#0F0F3A')};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
  margin-top: 12px;
`;

const SplitContainer = ({ children, title }) => (
  <StyledOurBusiness>
    <StyledTitleForOurBusiness>
      <H2 color="white">{title}</H2>
    </StyledTitleForOurBusiness>

    <StyledMainContainerForDoubleImage>
      {children}
    </StyledMainContainerForDoubleImage>
  </StyledOurBusiness>
);

export default SplitContainer;
