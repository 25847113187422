/* eslint-disable max-len */
import React from 'react';
import { useDispatch } from 'react-redux';

import Survey from './Survey';

export default ({ onSubmitAction, onChangeAction, model }) => {
  const dispatch = useDispatch();

  const onSubmit = ({ data, pages }) => dispatch({ type: onSubmitAction, payload: { data, pages } });
  const onChange = ({ data, pages }) => dispatch({ type: onChangeAction, payload: { data, pages } });

  return <Survey onSubmit={onSubmit} onChange={onChange} model={model} />;
};
