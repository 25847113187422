import React from 'react';
import netlifyIdentity from 'netlify-identity-widget';
import { useSelector } from 'react-redux';
import { Button } from '@allthingswww/client-act-shared';
import styled from 'styled-components';
import { getAuthenticated } from '../redux/user';

const Flex = styled.div`
display: flex;
flex-flow: row nowrap;
align-items: center;
justify-content: space-between;
min-height: 18vh;
@media (max-width: 820px) {
  flex-flow: column nowrap;
}
`;
const Center = styled.div`
 flex-basis: 50%;
 margin: auto;
`;

const Spacer = styled.div`
margin: 2rem;
display: flex;
flex-flow: row wrap;
justify-content: space-between;
width: 100%;
align-items: center;

@media (max-width: 820px) {
margin: 1rem 0rem;
}
`;

const Para = styled.p`
  line-height: 2;
`;

const Login = ({
  signupText = 'Not a member?',
  showSignup = true,
}) => {
  const signUp = () => netlifyIdentity.open('signup');
  const isUserLoggedIn = useSelector(getAuthenticated);

  return isUserLoggedIn ? null : (
    <Flex>
      <Center>
        <Spacer>
          {showSignup && (
            <div>
              <Spacer>
                <Para>
                  {signupText}
                </Para>
                <Button.Button variant handleClick={signUp}>Signup</Button.Button>
              </Spacer>
            </div>
          )}

        </Spacer>
      </Center>
    </Flex>

  );
};

export default Login;
