import React, { useState } from 'react';
import styled from 'styled-components';

const StyledDescription = styled.div`
  p {
    font-size: 20px;
    padding-bottom: 0.5em;
    line-height: 1.5rem;
    color: #0f0f3a;
    margin-right: 4rem;
  }

`;

const StyledReadMore = styled.div`
  
  color: blueviolet;
  text-decoration: underline;
  letter-spacing: 1px;
  cursor: progress;

`;

const ReadMore = ({ wordLimit, text }) => {
  const [readMore, setReadMore] = useState(false);
  const toggleReadMore = () => {
    setReadMore(!readMore);
  };
  return (
    <div>
      <StyledDescription><p>{readMore ? text.split(' ').slice(wordLimit).join(' ') : text.split(' ').slice(0, wordLimit).join(' ')}</p></StyledDescription>
      <StyledReadMore onClick={toggleReadMore}>
        {' '}
        {readMore ? 'show less' : 'read more'}
        {' '}
      </StyledReadMore>
    </div>
  );
};

export default ReadMore;
