/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { H1, H2 } from '@allthingswww/client-act-shared';

import SearchCard from './SearchResultCard';

class Search extends React.PureComponent {
  render() {
    const {
      searchResults,
      isSingular,
      searchCount,
      fetching,
      myRef,
    } = this.props;

    const sortedNames = (names) => names.slice().sort((a, b) => {
      if (a.companyName < b.companyName) { return -1; }
      if (a.companyName > b.companyName) { return 1; }
      return 0;
    });

    const filteredNames = (names) => names.filter((name) => name.membershipLevel === 'member');

    if (searchCount === 0) {
      return <H1>No Results</H1>;
    }

    if (fetching) {
      return <H1>Loading...</H1>;
    }

    const finalResults = filteredNames(sortedNames(searchResults)).filter(
      (res) => res.role.isMember && !res.role.isPending,
    );
    return (
      <>
        <H2>
          {finalResults.length}
          {`${isSingular ? ' Result' : ' Results'}`}
        </H2>
        <div ref={myRef}>
          {finalResults.map((result, index) => {
            if (index % 3 === 0) {
              return <SearchCard {...result} key={result.id} insertPageBreak />;
            }
            return (
              // eslint-disable-next-line no-underscore-dangle
              <SearchCard {...result} key={result.id} />
            );
          })}
        </div>
      </>
    );
  }
}

export default Search;
