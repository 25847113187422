/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import * as masterPieceUI from 'masterpiece-ui';
import GalleryItem from './GalleryItem';

const MainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const DescriptionContainer = styled.p`
  line-height: 2;
  text-align: center;
`;

const ImagesContainer = styled.div`

  display: flex;
  margin: 0px auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0;
  & > div {
    margin: 0 30px;
  }
`;

const Title = styled(masterPieceUI.H3)`
  font-weight: 600;
  font-size: 48px;
  line-height: 59px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #0f0f3a;
  margin-top: 40px;
  margin-bottom: 60px;
`;

const Tribute = ({ description }) => (
  <StaticQuery
    query={graphql`
      query TributeQuery {
        allMarkdownRemark(
          filter: { frontmatter: { template: { eq: "Tribute"} } }
          sort: { fields : [frontmatter___weight, frontmatter___title], order: [DESC, ASC]}
        ) {
          edges {
            node {
              id
              frontmatter {
                slug
                machineDate
                locationName
                address1
                address2
                city
                state
                zip
                title
                featuredImage
                subtitle
                url
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const tribute = data.allMarkdownRemark.edges.map((item) => ({
        id: item.node.id,
        ...item.node.frontmatter,
      }));

      return (
        <MainContainer>
          <Title>Membership Tribute</Title>
          <DescriptionContainer>{description}</DescriptionContainer>
          <ImagesContainer>
            {tribute.map((item) => <GalleryItem key={item.id} {...item} />)}
          </ImagesContainer>
        </MainContainer>
      );
    }}
  />
);

export default Tribute;
