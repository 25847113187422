import React from 'react';
import styled from 'styled-components';
import Image from './Image';

const StyledContainer = styled.div`
  color: #232122;
  width: 220px;
  height: 215px;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;

`;

const StyledImage = styled(Image)``;

const StyledName = styled.p`
  flex-grow: 1;
`;

const GalleryItem = ({ featuredImage, title, url }) => (
  <StyledContainer>
    <a href={url}>
      <StyledImage fileName={featuredImage} />
      <StyledName>{title}</StyledName>

    </a>

  </StyledContainer>
);

export default GalleryItem;
