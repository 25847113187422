import moment from 'moment';

export const eventCreator = (id, eventTitle, eventDescription, machineDate) => {
  const date = moment(machineDate).format('dddd, MMMM Do YYYY - h:mm:ss a');
  return {
    id, eventTitle, eventDescription, date, machineDate,
  };
};

let next = 0;
const quickEvent = () => {
  next += 1;
  const fancyDate = Math.ceil(
    Math.random() * 1800 * 24 * 60 * 60 * 1000
      + new Date('2018-01-01').getTime(),
  );

  return eventCreator(
    next,
    `${moment(fancyDate).format('MMMM')} Dinner Meeting`,
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris lorem, lacus a semper egestas leo.',
    fancyDate,
  );
};

const items = new Array(18).fill('').map(() => quickEvent());

const newItems = items.sort((a, b) => b.date - a.date);

export const findYears = (list) => Object.entries(
  list.reduce((prev, cur) => {
    const year = moment(cur.date).format('YYYY');
    return Object.assign(prev, { [year]: year });
  }, {}),
).map(([, value]) => ({ id: value, year: value }));

export default newItems;
