import React from 'react';
import styled from 'styled-components';
import { MaxWidth } from '@allthingswww/client-act-shared';

import BreakOutSection from './BreakOutSection';

const BackgroundWrapper = styled.div`
  background: ${(props) => props.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: ${(props) => props.height || '70vh'};
`;

const BreakOutSectionBackground = ({
  background, children, pre, post, className, height,
}) => (

  <BreakOutSection className={className}>
    {pre}
    <BackgroundWrapper background={background} height={height}>
      <MaxWidth>{children}</MaxWidth>
    </BackgroundWrapper>
    {post}
  </BreakOutSection>

);

export default BreakOutSectionBackground;
