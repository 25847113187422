/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import RevoCalendar from 'revo-calendar';
import moment from 'moment';
import _ from 'lodash';
import * as masterpieceUI from 'masterpiece-ui';
import { EventDetails } from '@allthingswww/client-act-shared';

import { getEvents } from '../redux/event';
import 'revo-calendar/dist/index.css';

const Calendar = styled(RevoCalendar)`
margin: 2rem 0;`;

const Flex = styled.div`
display: flex;
align-items: center;
`;
const Wrapper = styled.div`
margin: 2em 0;`;

const CurrentDateContainer = styled(masterpieceUI.H2)`
margin: 1em 0;

`;

export const EventsMdx = () => {
  // eslint-disable-next-line consistent-return
  const checkWindow = () => {
    // eslint-disable-next-line no-empty
    if (typeof window === 'undefined') {

    } else {
      return window.innerWidth <= 800;
    }
  };
  const allEvents = useSelector(getEvents);
  const [currentDate, setCurrentDate] = useState();
  return (
    <StaticQuery
      query={graphql`
        query EventsQuery {
        allMarkdownRemark(
            filter: { frontmatter: { template: { eq: "Event" } } }
          ) {
            edges {
              node {
                id
                frontmatter {
                  id
                  slug
                  locationName
                  address1
                  address2
                  city
                  state
                  zip
                  title
                  featuredImage
                  subtitle
                  memberFee 
                  nonMemberFee
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const addDateFieldFromMatchingEvents = (eventsFromFrontMatter) => {
          const eventsFromFrontMatterCopy = [...eventsFromFrontMatter];
          const eventsWithDate = eventsFromFrontMatterCopy.map((evt) => {
            const evtClone = _.cloneDeep(evt);
            const matchingEvent = allEvents.filter((e) => e._id === evt.id);
            evtClone.date = _.isEmpty(matchingEvent) ? null : matchingEvent[0].date;
            return { ...evtClone };
          });
          return eventsWithDate;
        };
        const eventsList = addDateFieldFromMatchingEvents(data.allMarkdownRemark.edges.map(
          (item) => ({
            id: item.node.id,
            ...item.node.frontmatter,
          }),
        ));
        const addEvent = () => null;
        const deleteEvent = () => null;

        const filteredEventsList = eventsList.filter((item) => moment(item.date).isSame(currentDate, 'day'));
        return (
          <Wrapper>
            <Calendar
              events={eventsList}
              style={{
                borderRadius: '5px',
                border: '5px solid var(--primaryColor)',
              }}
              highlightToday
              lang="en"
              primaryColor="#0F0F3A"
              secondaryColor="#c4c4c4"
              todayColor="#0F0F3A"
              textColor="#0F0F3A"
              indicatorColor="#0F0F3A"
              animationSpeed={300}
              sidebarWidth={180}
              detailWidth={280}
              showDetailToggler={false}
              showSidebarToggler={checkWindow}
              sidebarDefault={!checkWindow()}
              detailDefault={!checkWindow}
              onePanelAtATime={false}
              dateSelected={({ day, month, year }) => setCurrentDate(`${year}-${month + 1}-${day}`)}
              openDetailsOnDateSelection={false}
              timeFormat24
              showAllDayLabel={false}
              detailDateFormat="DD/MM/YYYY"
              deleteEvent={deleteEvent}
              addEvent={addEvent}
            />

            <Flex>
              <CurrentDateContainer>
                Events for
                {' '}
                {moment(currentDate).format('dddd, MM/DD/YY')}
              </CurrentDateContainer>
            </Flex>

            {filteredEventsList.length ? filteredEventsList
              .map((event) => (
                <EventDetails
        // eslint-disable-next-line react/jsx-props-no-spreading
                  {...event}
                  eventTitle={event.title}
                  eventDescription={event.subtitle}
                  date={moment(event.date).format('MM/DD/YY hh:mm A')}
                />
              ))
              : <div>No Events for this day</div>}
          </Wrapper>
        );
      }}
    />
  );
};
export default EventsMdx;
