import React from 'react';
import styled from 'styled-components';

const StyledContainerDown = styled.div`
  margin-top: -2px;
`;

const StyledContainerUp = styled.div`
  margin-bottom: -0.7rem;
`;

const StyledSVG = styled.svg`
  height: ${(props) => props.height || 'initial'};
  width: ${(props) => props.width || 'initial'};
  align-self: stretch;
`;

export const Up = ({ fillColor = '#0F0F3A', height, width }) => (
  <StyledContainerUp>
    <StyledSVG height={height} width={width} viewBox="0 0 1905 128" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M1924 0L1442.5 61.7143L961 12.8L479.5 128L-2 61.7143V128H479.5H961H1442.5H1924V0Z" fill={fillColor} />
    </StyledSVG>
  </StyledContainerUp>
);

export const Down = ({ fillColor = '#0F0F3A', height, width }) => (
  <StyledContainerDown>
    <StyledSVG height={height} width={width} viewBox="0 0 1905 144" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M-1.90771 144L475.315 63.2921L952.538 129.652L1429.76 0.519287L1906.98 63.2921V0.519287H1429.76H952.538H475.315H-1.90771V144Z" fill={fillColor} />
    </StyledSVG>
  </StyledContainerDown>
);

export default {
  Up,
  Down,
};
