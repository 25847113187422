import React, { useState } from 'react';
import connect from 'react-redux';
import styled, { css } from 'styled-components';
import { H2 } from '@allthingswww/client-act-shared';
import { prop, ifProp } from 'styled-tools';
import { Link } from '@reach/router';

import Image from './Image';
import UpArrow from '../images/upArrow.svg';
import DownArrow from '../images/downArrow.svg';
import ForwardArrow from '../images/forwardArrow.svg';
import ReadMore from './ReadMore';
import handleGroupImagesUploadcare from '../lib/handleGroupImagesUploadcare';
import checkNull from '../lib/checkNull';

const StyledCard = styled.div`
  @media print {
    page-break-inside: avoid;
    width: 7in;
  }

  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  border-top: 1px solid ${prop('theme.color.blue', '#0F0F3A')};
  padding: 2rem 0rem;

  ${ifProp(
    'insertPageBreak',
    css`
      @media print {
        page-break-before: always;
      }
    `,
    css`
      @media print {
        page-break-before: unset;
      }
    `
  )}

  & > :last-child {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    & > :first-child {
      padding-top: 5px;
    }
    & > button {
      margin-right: 1rem;
      &:hover {
        cursor: pointer;
      }
    }
  }
`;

const StyledImage = styled(Image)`
  max-width: 175px;
  width: 175px;
  @media print {
    width: 2.5in;
    max-width: 2.5in;
    max-height: 2.5in;
    page-break-inside: avoid;
  }
`;

const StyledFirstRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: start;

  & > div {
    justify-content: flex-start;
    align-items: baseline;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    & > span > H2 {
      margin: 0 0 2rem 0;
      @media print {
        font-size: 12pt;
      }
    }
  }

  & > div {
  }

  & > p {
    text-align: right;
    flex-grow: 3;
  }

  @media (max-width: 650px) {
    & > div & > span > H2 {
      margin: 1rem;
    }
    flex-direction: column;
    align-items: center;
    padding: 10px 0px;
    & > span {
      padding: 0;
      & > p {
        padding-bottom: 5px;
      }
    }
  }
`;

const StyledCompanyInfo = styled.div`
  p {
    font-size: 1.3rem;
    margin-bottom: 5px;
  }

  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  & > div {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-content: space-between;
    & > p {
      text-align: right;
      padding-bottom: 0.2rem;
    }
  }
`;

const Button = styled.button`
  @media print {
    display: none;
  }

  &:focus {
    outline: #0f0f3a auto 1px;
  }
`;
const Grow = styled.div`
  flex-grow: 1;
  margin: 0 40px;
`;
const MoreContainer = styled.div`
  display: flex;
`;

const StyledSecondRow = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-content: space-between;
  p {
    font-size: 20px;
    padding-bottom: 0.5em;
    line-height: 1.5rem;
    color: #0f0f3a;
    margin-right: 4rem;
  }
  
}
`;

const ImageContainer = styled.div`
  text-align: center;
  border: 1px solid #ccc;
  padding: 14px;
  border-radius: 12px;
  @media print {
    page-break-inside: avoid;
  }
`;
class SearchResultCard extends React.PureComponent {
  state = {
    showReadMore: false,
  };

  render() {
    const {
      _id,
      id,
      images = ['default_membership_logo.png'],
      companyName = '',
      companyEmail = '',
      companyPhone = '',
      companyDescription = '',
      companyAddress1 = '',
      companyCity = '',
      companyState = '',
      companyZip = '',
      companyWebsite = '',
      insertPageBreak = false,
    } = this.props;

    let urlappend = `${checkNull(companyAddress1)} ${checkNull(companyCity)} ${checkNull(companyState)} ${companyZip && companyZip}`;
    urlappend = `+${urlappend.split(' ').filter(s => s !== '').join(' +')}`;
    urlappend = urlappend.substring(1).replace(/\s+/g, '');
    const allImages = handleGroupImagesUploadcare(images);

    return (
      <StyledCard insertPageBreak={insertPageBreak}>
        <StyledFirstRow>
          {allImages && (<ImageContainer>
            <StyledImage fileName={allImages[0]} width="175px" height="175px" />
          </ImageContainer>)}
          <Grow>
            <span>
              {companyName && (<H2>{companyName}</H2>)}
              {companyEmail && (<p>
                <a href={`mailto: ${companyEmail}`}>{companyEmail}</a>
              </p>)}
            </span>
          </Grow>
          <StyledCompanyInfo>
            <div>
              {companyAddress1 && (<p>
                <a href={`https://www.google.com/maps/place/${urlappend}`} target="_blank" rel="noreferrer">
                  {companyAddress1}
                </a>
              </p>)}
              <p>
                <a href={`https://www.google.com/maps/place/${urlappend}`} target="_blank" rel="noreferrer">
                {companyCity && `${companyCity}, `}
                  {companyState && `${companyState}, `}
                  {companyZip && `${companyZip}`}
                </a>
              </p>
              {companyPhone && (<p>
                <a href={`tel: ${companyPhone}`}>{companyPhone}</a>
              </p>)}
              {companyWebsite && companyWebsite.length > 1 && companyWebsite.length <= 40 && <p>
                <a rel="noopener noreferrer" href={`${companyWebsite}`} target="_blank">{companyWebsite.replace(/(^\w+:|^)\/\//, '')}</a>
              </p>}
              {companyWebsite && companyWebsite.length > 40 && <p>
                <a rel="noopener noreferrer" href={`${companyWebsite}`} target="_blank">Visit Website</a>
              </p>}
            </div>
          </StyledCompanyInfo>
        </StyledFirstRow>

        <StyledSecondRow>
          {companyDescription == null ? (
            ' '
          ) : (
            <p>
              {this.state.showExtended
                ? companyDescription
                : companyDescription.split(' ').slice(0, 50).join(' ') + "..."}
            </p>
          )}
        </StyledSecondRow>
        <MoreContainer>
        { typeof companyDescription === "string" && companyDescription.length > 49 && <>
          {this.state.showExtended ? (
            <Button
              type="button"
              onClick={() => this.setState({ showExtended: false })}
            >
              See Less <img src={UpArrow} alt="up arrow" />
            </Button>
          ) : (
            <Button
              type="button"
              onClick={() => this.setState({ showExtended: true })}
            >
              Read More <img src={DownArrow} alt="down arrow" />
            </Button>
          )} </>}

          <Link
            to={`/member/${_id || id}`}
            state={{
              _id,
              companyImages: images,
              companyName,
              companyEmail,
              companyPhone,
              companyDescription,
              companyAddress1,
              companyCity,
              companyState,
              companyZip,
            }}
          >
            See Full Profile <img src={ForwardArrow} alt="forward arrow" />
          </Link>
        </MoreContainer>
      </StyledCard>
    );
  }
}

export default SearchResultCard;
