import React from 'react';
import styled from 'styled-components';
import { Up, Down } from '../ZigZagBlock';

import BreakOutSectionBackground from '../BreakOutSectionBackground';

const WaveUp = styled(Up)`
  width: 100vw;
`;

const WaveDown = styled(Down)`
  width: 100vw;
`;

const JaggedContainer = ({ fillColor = '#0F0F3A', children }) => (
  <BreakOutSectionBackground
    pre={<WaveUp fillColor={fillColor} />}
    post={<WaveDown fillColor={fillColor} />}
    background={fillColor}
  >
    {children}
  </BreakOutSectionBackground>
);

export default JaggedContainer;
