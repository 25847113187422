/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
import React, {
  useRef, useState, useEffect, useCallback,
} from 'react';
import EmblaCarouselReact from 'embla-carousel-react';

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const DotButton = ({ selected, onClick, ...remaining }) => (
  <button
    {...remaining}
    className={`embla__dot${selected ? ' is-selected' : ''}`}
    onClick={onClick}
  />
);

export const PrevButton = ({ enabled, onClick, ...remaining }) => (
  <button
    {...remaining}
    className="embla__button embla__button--prev"
    onClick={onClick}
    disabled={!enabled}
  >
    <svg className="embla__button__svg" viewBox="137.718 -1.001 366.563 644">
      <path d="M428.36 12.5c16.67-16.67 43.76-16.67 60.42 0 16.67 16.67 16.67 43.76 0 60.42L241.7 320c148.25 148.24 230.61 230.6 247.08 247.08 16.67 16.66 16.67 43.75 0 60.42-16.67 16.66-43.76 16.67-60.42 0-27.72-27.71-249.45-249.37-277.16-277.08a42.308 42.308 0 0 1-12.48-30.34c0-11.1 4.1-22.05 12.48-30.42C206.63 234.23 400.64 40.21 428.36 12.5z" />
    </svg>
  </button>
);

export const NextButton = ({ enabled, onClick, ...remaining }) => (
  <button
    {...remaining}
    className="embla__button embla__button--next"
    onClick={onClick}
    disabled={!enabled}
  >
    <svg className="embla__button__svg" viewBox="0 0 238.003 238.003">
      <path d="M181.776 107.719L78.705 4.648c-6.198-6.198-16.273-6.198-22.47 0s-6.198 16.273 0 22.47l91.883 91.883-91.883 91.883c-6.198 6.198-6.198 16.273 0 22.47s16.273 6.198 22.47 0l103.071-103.039a15.741 15.741 0 0 0 4.64-11.283c0-4.13-1.526-8.199-4.64-11.313z" />
    </svg>
  </button>
);

const EmblaCarouselComponent = ({ children, delay = 5000 }) => {
  const [embla, setEmbla] = useState(null);
  const [interactions, setInteractions] = useState(false);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const scrollTo = useCallback((index) => embla.scrollTo(index), [embla]);
  const scrollPrev = useCallback(() => embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla.scrollNext(), [embla]);

  useInterval(
    () => {
      if (!interactions) {
        if (selectedIndex === scrollSnaps.length - 1) {
          scrollTo(0);
        } else {
          scrollNext();
        }
      }
    },
    !interactions ? delay : null,
  );

  useEffect(() => {
    const onSelect = () => {
      setSelectedIndex(embla.selectedScrollSnap());
      setPrevBtnEnabled(embla.canScrollPrev());
      setNextBtnEnabled(embla.canScrollNext());
    };
    if (embla) {
      setScrollSnaps(embla.scrollSnapList());
      embla.on('select', onSelect);
      onSelect();
    }
    return () => embla && embla.destroy();
  }, [embla]);

  return (
    <div
      className="embla"
      onMouseEnter={() => setInteractions(true)}
      onMouseLeave={() => setInteractions(false)}
    >
      <EmblaCarouselReact
        className="embla__viewport"
        emblaRef={setEmbla}
        options={{ loop: true, autoplay: true }}
      >
        <div className="embla__container">
          {children.map((Child, index) => (
            <div className="embla__slide" key={index}>
              <div className="embla__slide__inner">{selectedIndex >= index ? Child : null}</div>
            </div>
          ))}
        </div>
      </EmblaCarouselReact>
      <div className="embla__dots">
        {scrollSnaps.map((snap, index) => (
          <DotButton
            aria-label={`Slide ${index}`}
            selected={index === selectedIndex}
            onClick={() => scrollTo(index)}
            key={index}
          />
        ))}
      </div>
      <PrevButton aria-label="Previous Button" onClick={scrollPrev} enabled={prevBtnEnabled} />
      <NextButton aria-label="Next Button" onClick={scrollNext} enabled={nextBtnEnabled} />
    </div>
  );
};

export default EmblaCarouselComponent;
