import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';

const StyledCenter = styled.div`
  width: 50%;
  margin: 0 auto;
  text-align: center;
  @media (max-width: ${prop('theme.screens.mobile', '820px')}) {
    width: 80%;
  }
`;

const Center = ({ children }) => (
  <StyledCenter>{children}</StyledCenter>
);

export default Center;
