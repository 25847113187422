import React from 'react';
import styled from 'styled-components';
import { H2 } from 'masterpiece-ui';
import { Button } from '@allthingswww/client-act-shared';
import { useDispatch } from 'react-redux';
import { navigate } from 'gatsby';

import { Actions } from '../../redux/signup';
import useForm from '../../../functions/utils/useForm';
import Option from '../Plan';

const FlexHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const FlexVertical = styled.div`
  display: flex;
  flex-direction: column;
`;

const Para = styled.p`
  width: 70%;
  margin: 0 auto;
  padding: 0.5rem 0rem;
  color: inherit;
  line-height: 1.5rem;
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  width: 5rem;
  margin-top: 3rem;
  padding-left: -3rem;
`;

const Options = [
  {
    id: 'option-1',
    name: 'Option 1',
    shortDescription: 'My business is actively engaged in subcontracting or supplying materials to the construction industry',
  },
  {
    id: 'option-2',
    name: 'Option 2',
    shortDescription: 'My business is actively engaged as general contractor or home builder in the construction industry',
  },
];

const PurchasePlanForm = () => {
  const dispatch = useDispatch();

  const { formData, setFormData, onSubmit } = useForm({
    selectedNatureOfBusiness: Options[0],
  }, (chosenOption) => {
    dispatch(Actions.addOrganizationType(chosenOption));
    navigate('/members/step1');
  });

  const { selectedNatureOfBusiness } = formData;

  return (
    <FlexVertical>
      <FlexHorizontal>
        {Options.map((option) => (
          <Option
            onClick={setFormData}
            data={option}
            key={option.id}
            selectedItem={formData}
            isSelected={option.id === selectedNatureOfBusiness.id}
          >
            <H2>{option.name}</H2>
            <Para>{option.shortDescription}</Para>
          </Option>
        ))}
      </FlexHorizontal>
      <ButtonWrapper>
        <Button.Button variant handleClick={onSubmit}>Continue</Button.Button>
      </ButtonWrapper>
    </FlexVertical>
  );
};

export default PurchasePlanForm;
