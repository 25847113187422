import React from 'react';
import styled from 'styled-components';
import { Input, Button } from '@allthingswww/client-act-shared';
import { useDispatch, connect } from 'react-redux';
import { navigate } from 'gatsby';

import useForm from '../../../functions/utils/useForm';
import { Actions } from '../../redux/signup';

const Wrapper = styled.div`
  margin-top: 3rem;
  text-align: center;
`;

const StepTwo = ({ selectedNatureOfBusiness }) => {
  const dispatch = useDispatch();

  const { formData, onChange, onSubmit } = useForm({
    companyPoBox: '',
    companyCity: '',
    companyState: '',
    companyZip: '',
    companyWebsite: '',
    principaTypeOfBusiness: '',
    yearsInBusiness: '',
    nameOfSeniorOfficerOrLocalManager: '',
    nameOfRepresentative: '',
    alternateRepresentative: '',
    nameOfSponsor: '',
  }, (data) => {
    dispatch(Actions.addSignUpInfo({
      stepTwo: { ...data },
    }));
    if (!selectedNatureOfBusiness) {
      navigate('/members/type');
    } else if (selectedNatureOfBusiness.id === 'option-1') {
      navigate('/members/step3a');
    } else {
      navigate('/members/step3b');
    }
  });

  const {
    companyPoBox, companyCity, companyState, companyZip, companyWebsite, principaTypeOfBusiness,
    yearsInBusiness, nameOfSeniorOfficerOrLocalManager,
    nameOfRepresentative,
    alternateRepresentative,
    nameOfSponsor,
  } = formData;

  return (
    <form onSubmit={onSubmit}>
      <Wrapper>
        <Input
          value={companyPoBox}
          handleChange={onChange}
          type="text"
          name="companyPoBox"
          placeholder="Street or P.O. Box**"
          isRequired
        />
        <Input
          value={companyCity}
          handleChange={onChange}
          type="text"
          name="companyCity"
          placeholder="City*"
          isRequired
        />
        <Input
          value={companyState}
          handleChange={onChange}
          type="text"
          name="companyState"
          placeholder="State*"
          isRequired
        />
        <Input
          value={companyZip}
          handleChange={onChange}
          type="text"
          name="companyZip"
          placeholder="ZIP*"
          isRequired
        />
        <Input
          value={companyWebsite}
          handleChange={onChange}
          type="text"
          name="companyWebsite"
          placeholder="Website*"
          isRequired
        />
      </Wrapper>
      <Wrapper>
        <Input
          value={principaTypeOfBusiness}
          handleChange={onChange}
          type="text"
          name="principaTypeOfBusiness"
          placeholder="Principle type of business"

        />
        <Input
          value={yearsInBusiness}
          handleChange={onChange}
          type="text"
          name="yearsInBusiness"
          placeholder="Years in Business"

        />
        <Input
          value={nameOfSeniorOfficerOrLocalManager}
          handleChange={onChange}
          type="text"
          name="nameOfSeniorOfficerOrLocalManager"
          placeholder="Name of Senior Officer or Local Manager"
          isRequired
        />
        <Input
          value={nameOfRepresentative}
          handleChange={onChange}
          type="text"
          name="nameOfRepresentative"
          placeholder="Name of Representative"
          isRequired
        />
        <Input
          value={alternateRepresentative}
          handleChange={onChange}
          type="text"
          name="alternateRepresentative"
          placeholder="Alternate Representative"
          isRequired
        />
        <Input
          value={nameOfSponsor}
          handleChange={onChange}
          type="text"
          name="nameOfSponsor"
          placeholder="Name of sponsor (if any)"
          isRequired
        />
      </Wrapper>
      <Wrapper>
        <Button.Button variant>Continue</Button.Button>
      </Wrapper>
    </form>
  );
};

const mapStateToProps = ({ signup }) => {
  const {
    data: {
      selectedNatureOfBusiness,
    },
  } = signup;
  return ({
    selectedNatureOfBusiness,
  });
};

export default connect(mapStateToProps)(StepTwo);
