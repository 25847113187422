import {
  useState, useEffect, useCallback,
} from 'react';
import * as JsSearch from 'js-search';

const Search = ({
  itemsToBeSearched = [], isSearching, indexList = [], uidFieldName,
}) => {
  const [search, setSearch] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [indexes, setIndexes] = useState(indexList);

  const updateSearchQuery = useCallback((query) => {
    setSearchQuery(query);
  }, []);

  useEffect(() => {
    const rebuildIndex = () => {
      const dataToSearch = new JsSearch.Search(uidFieldName);

      dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
      dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();
      dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex(uidFieldName);
      indexes.forEach((index) => {
        dataToSearch.addIndex(index);
      });
      dataToSearch.addDocuments(itemsToBeSearched);
      setSearch(dataToSearch);
    };
    rebuildIndex();
  }, [itemsToBeSearched, indexes, uidFieldName]);

  useEffect(() => {
    const searchData = () => {
      const queryResult = search.search(searchQuery);
      setSearchResults(queryResult);
    };

    if (searchQuery.length >= 2) {
      searchData();
    }
  }, [searchQuery, search]);

  const isSingular = searchResults.length === 1;
  const searchCount = searchResults.length;

  return [{
    isSearching,
    searchResults,
    isSingular,
    searchCount,
    indexes,
  },
  {
    updateSearchQuery,
    setSearchResults,
    setIndexes,
  }];
};

export default Search;
