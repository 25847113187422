import React from 'react';
import styled from 'styled-components';
// import { H1, H2, Button } from 'masterpiece-ui';
import InfoSection from './InfoSection';

// const Flex = styled.div`
// display: flex;
// flex-wrap: wrap;
// `;

const Pane50Container = styled.div`
width: 50%;
margin: 0 1em;
`;

export const ImagePane = ({
  children, title, subTitle, image, actionText, actionTarget, primary, textColor,
}) => (

  <InfoSection
    primary={primary}
    textColor={textColor}
    descTitle={subTitle}
    desc={children}
    buttonText={actionText}
    title={title}
    image={image}
    actionTarget={actionTarget}
  />

);
export const Pane50 = ({ children }) => <Pane50Container>{children}</Pane50Container>;
