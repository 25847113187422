/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import { get } from 'lodash';
import { useStaticQuery, graphql } from 'gatsby';

import { H2 } from '@allthingswww/client-act-shared';
import { getPublishedEvents } from '../redux/event';
import EventList from './EventsList';
import { findYears } from '../assets/events';
import H1 from './StyledH1';
import CarouselItems from './CarouselItems';
import BreakOutSection from './BreakOutSection';

const query = graphql`
  query EventsData {
  allMarkdownRemark(
    filter: {
      frontmatter: {
        template: { regex: "/Home/" }
      }
    }
  ) {
    totalCount
    edges {
      node {
        frontmatter {
          slug
          carouselData
        }
      }
    }
  }
}
`;

const MainContainer = styled.div`
  width: 1024px;
  max-width: 100vw;
  margin: auto;

  & H1 {
    content: '';
    display: block;
    padding-right: 10px;
    margin-top: 20px;
  }
  @media only screen and (max-width: 1024px) {
    width: unset;
  }
`;

const Center = styled.div`
  margin: 0 auto;
  text-align: center;
`;

const YearButton = styled.button`
  &:focus {
    outline: #0f0f3a auto 1px;
  }
  &:hover {
    cursor: pointer;
  }
`;

const EventListsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  flex-grow: 2;
  @media all and (max-width: 1280px) {
    flex-direction: column;
    align-items: center;
  }
`;

const MainContainerWithoutHeading = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  flex-flow: row;
`;
const YearContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  span {
    font-size: 20 px;
    color: #0f0f3a;
    font-family: piazzolla;
    font-weight: 600;
  }

  li {
    list-style: none;
    margin: 16px 0px;
    min-width: max-content;
  }

  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;

  color: #000000;

  @media only screen and (max-width: 636px) {
    align-items: center;
  }
`;
const YearListUl = styled.ul`
  @media only screen and (max-width: 636px) {
    display: flex;
    & > li {
      margin: 10px 6px;
    }
  }
`;

const Events = () => {
  const [currentYear, setYear] = useState(`C,${moment().format('yyyy')}`);
  // eslint-disable-next-line no-unused-vars
  const [isShowing, setShowing] = useState(false);
  const allPublishedEvents = useSelector(getPublishedEvents);
  const yearList = findYears(allPublishedEvents);
  const { allMarkdownRemark } = useStaticQuery(query);
  const { edges } = allMarkdownRemark;
  const { node } = edges[0];
  const { frontmatter } = node;
  const { carouselData: stringifiedCarouselData } = frontmatter;
  const carouselData = JSON.parse(stringifiedCarouselData);

  function whichListToRender(year) {
    setYear(year);
  }

  function nextAndPreviousYears(year) {
    if (moment().diff(year) < 0) {
      return true;
    }
    return false;
  }

  function desktopView() {
    const [currentOrPast, year] = currentYear.split(',');
    const sortedEvents = allPublishedEvents.sort((a, b) => Date.parse(b.date) - Date.parse(a.date));
    let filteredList = [];
    if (currentOrPast === 'C') {
      filteredList = sortedEvents.filter(
        (item) => moment(item.date).format('YYYY') === year,
      );
    } else {
      filteredList = sortedEvents.filter(
        (item) => moment(item.date).format('YYYY') === year,
      );
    }
    if (filteredList.length <= 3) {
      return <EventList events={filteredList} />;
    }

    const content = [];
    const length = parseInt(filteredList.length / 3, 10) + 1;
    for (let i = 0; i < parseInt(length, 10); i += 1) {
      const id = `${get(filteredList[i], 'id')}`;
      content.push(
        <EventList key={id} events={filteredList.splice(0, 3)} />,
      );
    }
    return <>{content}</>;
  }

  return (
    <MainContainer>
      <H1>Events</H1>
      <BreakOutSection>
        <Center>
          <H2>Recent Events</H2>
        </Center>
        <CarouselItems
          carouselData={carouselData}
          title=""
          miniTitle=""
        />
      </BreakOutSection>

      <MainContainerWithoutHeading>
        <YearContainer>
          <span>Events By Year</span>
          <YearListUl>
            {yearList.map((year) => (
              <li key={year.id}>
                {nextAndPreviousYears(year) ? null : (
                  <YearButton
                    onClick={() => whichListToRender(`P,${year.id}`)}
                  >
                    {year.year === 'Invalid date' ? 'Other Events' : year.year}
                  </YearButton>
                )}
              </li>
            ))}
          </YearListUl>
        </YearContainer>
        <EventListsContainer>{desktopView()}</EventListsContainer>
      </MainContainerWithoutHeading>
    </MainContainer>
  );
};

export default Events;
