import * as masterPieceUI from 'masterpiece-ui';
import styled /* , { css } */ from 'styled-components';
import '@fontsource/piazzolla';

const StyledH2 = styled(masterPieceUI.H2)`
  font-family: piazzolla;
  font-size: 30px;
  padding-bottom: 0.5em;
  color: #0F0F3A;
  font-weight: 600;
  margin: 2rem 0;
  margin-left: 0;
  margin-bottom: 2rem ;


`;

export default StyledH2;
